//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { AdminsService } from '../../services/admins.service.js';
import { PrescriptionService } from '@/services/prescription.service';
export default {
  props: {
    prescriptionData: {
      type: Object,

      default() {
        return {};
      }

    }
  },

  data() {
    return {
      alert: {
        show: false,
        message: ''
      },
      showDialog: false,
      selectedType: 'Package',
      selectedIssue: '',
      notes: '',
      issues: {
        Package: ['Shipping Delay - Pharmacy', 'Shipping Delay - Carrier', 'Product Damaged', 'Product Never Arrived', 'Incorrect Medication Sent']
      },
      buttonDisabled: false
    };
  },

  watch: {
    showDialog() {
      this.selectedType = 'Package';
      this.notes = '';
      this.alert.show = false;
      this.buttonDisabled = false;
      this.selectedIssue = '';
    },

    selectedType(type) {
      this.selectedIssue = this.issues[type][0];
    }

  },
  methods: {
    async submitReport() {
      if (!this.notes || !this.selectedIssue) {
        this.alert.message = 'Please fill in all the fields.';
        this.alert.show = true;
        return;
      }

      const body = {
        note: this.notes,
        type: this.selectedType,
        issue: this.selectedIssue
      };

      try {
        this.buttonDisabled = true;
        const {
          data
        } = await AdminsService.reportIssue(this.prescriptionData.patientid, body);
        await PrescriptionService.sendReplacement(this.prescriptionData.id);

        if (data) {
          this.showDialog = false;
        }

        this.$emit('refresh-prescriptions');
      } catch (err) {
        this.alert.message = err;
        this.alert.show = true;
      } finally {
        this.buttonDisabled = false;
      }
    }

  }
};