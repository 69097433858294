/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            "hide-header": "",
            "hide-footer": "",
            centered: "",
            "no-close-on-backdrop": "",
            size: "md"
          },
          model: {
            value: _vm.showDialog,
            callback: function($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog"
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "b-alert",
                {
                  attrs: { variant: "warning" },
                  model: {
                    value: _vm.alert.show,
                    callback: function($$v) {
                      _vm.$set(_vm.alert, "show", $$v)
                    },
                    expression: "alert.show"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]
              ),
              _c(
                "b-row",
                { staticClass: "mb-4", attrs: { "align-h": "between" } },
                [
                  _c("b-col", { attrs: { cols: "10" } }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/icon_logo_green.svg"),
                        alt: ""
                      }
                    })
                  ]),
                  _c("b-col", { staticClass: "text-right" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function($event) {
                            _vm.showDialog = false
                          }
                        }
                      },
                      [
                        _c("b-icon", {
                          attrs: { icon: "x-circle-fill", variant: "primary" }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "3" } }, [
                    _c("span", { staticClass: "text-primary" }, [
                      _vm._v("Replacement")
                    ])
                  ]),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { cols: "8" } }, [
                            _c("span", { staticClass: "text-primary" }, [
                              _vm._v("Type")
                            ]),
                            _c("p", [_vm._v("Package")])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-3" },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "8" } },
                            [
                              _c("span", { staticClass: "text-primary" }, [
                                _vm._v("Issue")
                              ]),
                              _c("b-form-select", {
                                attrs: {
                                  options: _vm.issues[_vm.selectedType]
                                },
                                model: {
                                  value: _vm.selectedIssue,
                                  callback: function($$v) {
                                    _vm.selectedIssue = $$v
                                  },
                                  expression: "selectedIssue"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-3" },
                        [
                          _c(
                            "b-col",
                            [
                              _c("span", { staticClass: "text-primary" }, [
                                _vm._v("Notes")
                              ]),
                              _c("b-form-textarea", {
                                attrs: { rows: "4" },
                                model: {
                                  value: _vm.notes,
                                  callback: function($$v) {
                                    _vm.notes = $$v
                                  },
                                  expression: "notes"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-4" },
                        [
                          _c(
                            "b-col",
                            { attrs: { offset: "2", cols: "10" } },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "mr-3",
                                          attrs: {
                                            block: "",
                                            variant: "outline-primary",
                                            pill: "",
                                            disabled: _vm.buttonDisabled
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.showDialog = false
                                            }
                                          }
                                        },
                                        [_vm._v(" Close ")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            block: "",
                                            variant: "primary",
                                            pill: "",
                                            disabled: _vm.buttonDisabled
                                          },
                                          on: { click: _vm.submitReport }
                                        },
                                        [_vm._v(" Submit ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }